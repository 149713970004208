<template>
  <v-card>
    <v-card-text>
      <v-card
        outlined
        class="mb-3"
      >
        <v-card-title class="mb-2">
          Dados do Cliente
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
              class="py-0"
            >
              <v-text-field
                v-model="termLgpdItem.client_name"
                outlined
                dense
                readonly
                label="Nome"
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
              class="py-0"
            >
              <v-text-field
                v-model="termLgpdItem.cpf_cnpj"
                outlined
                dense
                readonly
                label="CPF/CNPJ"
              />
            </v-col>
            <!-- <v-col
                cols="12"
                md="6"
                lg="6"
                class="py-0"
              >
                <v-text-field
                  v-model="termLgpdItem.email"
                  outlined
                  dense
                  label="Email"
                />
              </v-col> -->
            <v-col
              cols="12"
              md="6"
              lg="6"
              class="py-0"
            >
              <v-text-field
                v-model="client_phone"
                outlined
                dense
                label="Telefone"
              />
            </v-col>
            <v-col
              v-if="isEdit"
              cols="12"
              md="6"
              lg="6"
              class="py-0"
            >
              <v-text-field
                disabled
                label="Assinatura"
                class="pt-0"
                hide-details
              ></v-text-field>
              <v-img
                :src="termLgpdItem.customer_subscription"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        outlined
        class="mb-3"
      >
        <v-card-title class="mb-2">
          Dados da Proposta
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
              class="py-0"
            >
              <v-text-field
                v-model="termLgpdItem.proposal_number"
                outlined
                dense
                readonly
                label="Proposta"
              />
            </v-col>
            <!-- <v-col
                cols="12"
                md="6"
                lg="6"
                class="py-0"
              >
                <v-text-field
                  v-model="termLgpdItem.receipt_number"
                  outlined
                  dense
                  label="Recibo"
                />
              </v-col> -->
            <v-col
              cols="12"
              md="6"
              lg="6"
              class="py-0"
            >
              <v-text-field
                v-model="termLgpdItem.contract_date"
                outlined
                dense
                type="date"
                readonly
                label="Data da Venda"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
              class="py-0"
            >
              <v-text-field
                v-if="termLgpdItem.vehicle_model"
                v-model="termLgpdItem.vehicle_model.vehicle_model"
                outlined
                dense
                readonly
                label="Modelo"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="3"
              class="py-0"
            >
              <v-switch
                v-model="termLgpdItem.bid_fixed"
                label="Lance Fixo"
                readonly
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="3"
              class="py-0"
            >
              <v-switch
                v-model="termLgpdItem.life_insurance"
                label="Seguro"
                readonly
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card outlined>
        <v-card-title class="mb-2">
          Dados do Vendedor
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              lg="6"
              class="py-0"
            >
              <v-text-field
                v-model="userData.name"
                outlined
                dense
                label="Nome"
                readonly
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="py-0"
            >
              <v-text-field
                v-model="userData.phone"
                outlined
                readonly
                dense
                label="Telefone"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div
        style="width: 100%;"
        class="d-flex justify-end mt-3"
      >
        <v-btn
          color="info"
          :disabled=" (client_phone === termLgpdItem.client_phone) || isLoading "
          @click="updateLgpd"
        >
          <span v-if="!isLoading">

            Atualizar
          </span>
          <v-progress-circular
            v-else
            indeterminate
            color="white"
            size="20"
          ></v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'

export default {

  name: 'LgpdUpdateModal',

  props: {
    termLgpdItem: {
      type: Object,
      default: () => ({}),
    },
    listVehiclesItems: {
      type: Array,
      default: () => [],
    },
    userData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      idVehicle: null,
      isEdit: false,
      client_phone: '',
      isLoading: false,
    }
  },

  created() {
    this.client_phone = this.termLgpdItem.client_phone
  },

  methods: {
    async updateLgpd() {
      try {
        this.isLoading = true
        await axiosIns.put(`/api/v1/integration/lgpd_term/update/${this.termLgpdItem.id}`, {
          client_phone: this.client_phone,
        })
        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Os dados foram atualizados com sucesso.',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        console.log('Erro ao atualizar o termo lgpd', error)
      } finally {
        this.termLgpdItem.client_phone = this.client_phone
        this.isLoading = false
        this.$emit('getTableData')
      }

      // const data = {
      //   id: this.termLgpdItem.id,
      //   client_name: this.termLgpdItem.client_name,
      //   cpf_cnpj: this.termLgpdItem.cpf_cnpj,
      //   client_phone: this.termLgpdItem.client_phone,
      //   proposal_number: this.termLgpdItem.proposal_number,
      //   contract_date: this.termLgpdItem.contract_date,
      //   bid_fixed: this.termLgpdItem.bid_fixed,
      //   life_insurance: this.termLgpdItem.life_insurance,
      //   vehicle_id: this.idVehicle,
      // };
      // this.$emit('updateLgpd', data);
    },
  },

}
</script>

<style>

</style>
