import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,[_vm._v(" Termo LGPD ")]),_c(VDivider,{staticClass:"mt-0"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8","lg":"4"}},[_c(VAutocomplete,{attrs:{"items":_vm.select_options,"item-text":"text","item-value":"value","label":"Tipo do filtro","outlined":"","dense":""},model:{value:(_vm.typeFilterSelected),callback:function ($$v) {_vm.typeFilterSelected=$$v},expression:"typeFilterSelected"}})],1),_c(VCol,{attrs:{"cols":"12","md":"8","lg":"6"}},[_c(VTextField,{staticClass:"user-search mb-4",attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Buscar Cliente","clearable":"","outlined":"","hide-details":"","dense":""},on:{"click:append":_vm.filterQuickSearch,"click:clear":_vm.filterReset},model:{value:(_vm.filterOptions.input_value),callback:function ($$v) {_vm.$set(_vm.filterOptions, "input_value", $$v)},expression:"filterOptions.input_value"}})],1)],1),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c(VBtn,{staticClass:"mb-5 pl-4",attrs:{"color":"primary","outlined":""},on:{"click":_vm.addTermLgpd}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlaylistPlus))]),_c('span',{staticClass:"pl-2"},[_vm._v("Novo")])],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.termLgpdList,"loading":_vm.isLoadingTableData,"loading-text":"Carregando dados...","page":_vm.pageOptions.page,"items-per-page":_vm.pageOptions.itemsPerPage,"fixed-header":"","height":_vm.heightDynamic,"hide-default-footer":"","disable-sort":""},on:{"update:page":function($event){return _vm.$set(_vm.pageOptions, "page", $event)},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"me-2",attrs:{"icon":""},on:{"click":function($event){return _vm.editTermLgpd(item)}}},'v-btn',attrs,false),on),[(item.id === _vm.modalOptions.loading ? false : true)?_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")]):_vm._e(),(item.id === _vm.modalOptions.loading ? true : false)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":25,"color":"gray"}}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])]),_c(VTooltip,{attrs:{"top":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"me-2",attrs:{"icon":"","color":"purple"},on:{"click":function($event){return _vm.resendLinkWhatsapp(item)}}},'v-btn',attrs,false),on),[(item.id === _vm.modalOptions.loading2 ? false : true)?_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiAutorenew)+" ")]):_vm._e(),(item.id === _vm.modalOptions.loading2 ? true : false)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":25,"color":"gray"}}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Reenviar link")])]),_c(VTooltip,{attrs:{"top":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"me-2",attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.handleOpenUpdateModal(item)}}},'v-btn',attrs,false),on),[(item.id === _vm.modalOptions.loading3 ? false : true)?_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]):_vm._e(),(item.id === _vm.modalOptions.loading3 ? true : false)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":25,"color":"gray"}}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Editar dados")])])]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.noDataText)+" ")]},proxy:true},{key:"item.client_phone",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"top":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.chatWhatsapp(item.client_phone)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiWhatsapp)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Entrar em contato")])]),_vm._v(" "+_vm._s(item.client_phone)+" ")]}}])}),_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"pt-2 pb-1"},[_c(VRow,[_c(VCol,{staticClass:"d-flex justify-center",attrs:{"lg":"12","cols":"12"}},[_c(VPagination,{attrs:{"total-visible":"6","length":_vm.pageOptions.pageCount},on:{"input":_vm.updatePage},model:{value:(_vm.pageOptions.page),callback:function ($$v) {_vm.$set(_vm.pageOptions, "page", $$v)},expression:"pageOptions.page"}})],1)],1)],1)],1),_c(VDialog,{attrs:{"width":"900"},model:{value:(_vm.modalOptions.modal),callback:function ($$v) {_vm.$set(_vm.modalOptions, "modal", $$v)},expression:"modalOptions.modal"}},[_c('lgpd-modal-vendor',{attrs:{"close-modal":_vm.closeModal,"is-edit":_vm.modalOptions.editItem,"is-valid":_vm.modalOptions.validItem,"is-disable":_vm.modalOptions.disableItem,"is-remake":_vm.modalOptions.remakeItem,"send-contract":_vm.modalOptions.sendContract,"term-lgpd-item":_vm.modalOptions.termLgpdItem,"user-data":_vm.userData,"updated-table":_vm.updatedTable}})],1),_c(VDialog,{attrs:{"width":"900"},model:{value:(_vm.openUpdateModal),callback:function ($$v) {_vm.openUpdateModal=$$v},expression:"openUpdateModal"}},[_c('LgpdUpdateModal',{attrs:{"close-modal":_vm.closeUpdateModal,"term-lgpd-item":_vm.modalOptions.termLgpdItem,"user-data":_vm.userData},on:{"getTableData":_vm.handleUpdateTable}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }